import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Nav, Footer } from '@bitcoin-portal/bitcoincom-universal';
import QRCode from 'qrcode.react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import toast from 'toasted-notes';
import Lottie from 'lottie-react-web';
import { BITBOX } from 'bitbox-sdk';
import InvoicePaid from './InvoicePaid.json';
import 'toasted-notes/src/styles.css';
import {
  Wrapper,
  HeadSection,
  FileSection,
  FileContentBlock,
  QrContentBlock,
  FileTitle,
  FileTable,
  FileTr,
  FileTd,
  ValueTd,
  HashTd,
  QrWrapper,
  ConfirmedIcon,
  ConfirmedText,
  AnchorLink,
  HeaderLinkText,
  HeaderLink,
  FileLoader,
  ProofButton,
  ApiErrorCard,
  Alert,
} from './styled';
import SEO from './SEO';
import bchLogo from '../../../static/images/uploads/bch-icon-qrcode.png';

const bitbox = new BITBOX({
  restURL: 'https://rest.bitcoin.com/v2/',
});

// Prod
const notaryBackendBase = 'https://notary-api.bitcoin.com/api/v1';
// Dev
// const notaryBackendBase = 'http://localhost:3003/api/v1';
// Staging
// const notaryBackendBase = 'https://notary-api.btctest.net';

class Proof extends React.Component {
  constructor(props) {
    super(props);
    this.wsTimeout = 250; // Initial timeout duration as a class variable
    this.watchTxInterval = 10000;
    this.parseHashUri = this.parseHashUri.bind(this);
    this.initializeWebsocket = this.initializeWebsocket.bind(this);
    this.reconnectWebsocket = this.reconnectWebsocket.bind(this);
    this.checkSeenTx = this.checkSeenTx.bind(this);
    this.setCheckAnchorInterval = this.setCheckAnchorInterval.bind(this);
    this.clearCheckAnchorInterval = this.clearCheckAnchorInterval.bind(this);
    this.handleUriCopied = this.handleUriCopied.bind(this);
    this.isHash = this.isHash.bind(this);
    this.isSHA256Hash = this.isSHA256Hash.bind(this);
    // this.rewatchProof = this.rewatchProof(this);
    /*
    this.watchProofAddressForAnchor = this.watchProofAddressForAnchor.bind(
      this,
    );
    */
    // this.intervalDebug = this.intervalDebug.bind(this);

    this.state = {
      hash: '',
      address: '',
      price: null,
      proofState: null,
      inferredProofState: null,
      balanceSats: 0,
      inferredBalanceSats: 0,
      serverStamp: null,
      anchorBroadcast: null,
      inferredAnchorBroadcast: null,
      anchorTx: null,
      inferredAnchorTxid: null,
      ws: null,
      checkAnchorInterval: null,
      apiError: false,
      loading: true,
      hashError: false,
      lastTxParsed: '',
    };
  }

  componentDidMount() {
    this.parseHashUri();
    // this.intervalDebug();
  }

  setCheckAnchorInterval() {
    const self = this;
    const timer = this.watchTxInterval;
    // eslint-disable-next-line func-names
    const checkAnchorInterval = setInterval(function() {
      self.watchProofAddressForAnchor();
    }, timer);
    this.setState({ checkAnchorInterval });
  }

  // eslint-disable-next-line consistent-return
  getProof(hash) {
    const { loading } = this.state;
    if (!loading) {
      this.setState({ loading: true });
    }
    if (!this.isSHA256Hash(hash)) {
      return this.setState({ loading: false, hashError: true });
    }
    // console.log(`getProof(${hash})`);
    const getProofEndpoint = `${notaryBackendBase}/proofs/${hash}`;
    fetch(getProofEndpoint)
      .then(response => response.json())
      .then(
        // eslint-disable-next-line consistent-return
        response => {
          console.log(response);
          /* Sample successful response from notary.bitcoin.com
          {
            "status": "ok",
            "data": {
              "id": 3,
              "hash": "3f772cc2096a317100504d7958ab4c5467729f49205f7ca8bcac703807f71e27",
              "address": "127NN3Q4QHLqVMeTMeefCVVNdie3whcbpq",
              "price": 5000,
              "stamp": 1585179532,
              "bcast": null,
              "anchor": [],
              "funds": [],
              "state": "unpaid",
              "balance": 5000
            }
          }
          */

          if (typeof response.data !== 'undefined') {
            const {
              address,
              price,
              stamp,
              bcast,
              anchor,
              state,
              balance,
            } = response.data;

            // If stamp is more than three hours ago, refresh this proof
            const serverDate = new Date(stamp * 1000);
            const now = new Date();
            const serverSawMsAgo = now - serverDate;
            const serverSawSecsAgo = Math.round(serverSawMsAgo / 1000);
            const serverSawMinsAgo = Math.round(serverSawSecsAgo / 60);
            const serverSawHrsAgo = Math.round(serverSawMinsAgo / 60);
            /*
            console.log(
              `Proof stamped by server ${serverSawSecsAgo} second ago`,
            );
            console.log(`Proof stamped by server ${serverSawHrsAgo} hours ago`);
            */
            if (serverSawHrsAgo > 3 && state !== 'confirmed') {
              console.log(
                `Server may not be watching this transaction, adding to watch list`,
              );
              this.rewatchProof(hash);
            }

            if (state === 'anchoring') {
              return this.setState(
                {
                  address,
                  price,
                  proofState: state,
                  balanceSats: balance,
                  serverStamp: stamp,
                  anchorBroadcast: bcast,
                  anchorTx: anchor,
                  loading: false,
                  apiError: false,
                },
                this.setCheckAnchorInterval(),
              );
            }

            if (state !== 'confirmed') {
              // Notary backend calls unpaid pending in api response, correct this
              let renderedProofState = state;
              if (balance === price) {
                renderedProofState = 'unpaid';
              }
              return this.setState(
                {
                  address,
                  price,
                  proofState: renderedProofState,
                  balanceSats: balance,
                  serverStamp: stamp,
                  anchorBroadcast: bcast,
                  anchorTx: anchor,
                  loading: false,
                  apiError: false,
                },
                this.initializeWebsocket(address, hash),
              );
            }

            return this.setState({
              address,
              price,
              proofState: state,
              balanceSats: balance,
              serverStamp: stamp,
              anchorBroadcast: bcast,
              anchorTx: anchor,
              loading: false,
              apiError: false,
            });
          }
          if (
            typeof response.error !== 'undefined' &&
            response.error === 'Hash not found'
          ) {
            console.log(`Hash not found`);
            return this.postNewProof(hash);
          }
          if (
            typeof response.error !== 'undefined' &&
            response.error === 'Invalid Hash provided'
          ) {
            return this.setState({ loading: false, hashError: true });
          }
        },
        err => {
          console.log(`Error in getting ${hash} at ${getProofEndpoint}:`);
          console.log(err);
          return this.setState({ apiError: true, loading: false });
        },
      );
  }

  isSHA256Hash(hash) {
    return this.isHash(hash) && hash.length === 64;
  }

  // eslint-disable-next-line class-methods-use-this
  isHash(hash) {
    const hashReg = new RegExp(/^[0-9a-f]*$/, 'i');
    if (typeof hash !== 'string') return false;
    return hashReg.test(hash);
  }

  // eslint-disable-next-line class-methods-use-this
  handleUriCopied() {
    const {
      intl: { formatMessage },
    } = this.props;
    const toastMsg = formatMessage({ id: 'proof.toast.copied' });
    toast.notify(toastMsg, {
      position: 'bottom-right',
      duration: 1500,
    });
  }

  clearCheckAnchorInterval() {
    const { checkAnchorInterval } = this.state;
    // console.log(`clearCheckAnchorInterval()`);
    checkAnchorInterval && clearInterval(checkAnchorInterval);
    this.setState({ checkAnchorInterval: null });
  }
  /*
  intervalDebug() {
    //console.log(`intervalDebug()`);
    const { hash } = this.state;
    const self = this;
    const timer = this.watchTxInterval;
    const checkAnchorInterval = setInterval(function() {
      self.watchProofAddressForAnchor();
    }, timer);
    this.setState({ checkAnchorInterval });
  }
  */

  postNewProof(hash) {
    // console.log(`postNewProof(${hash})`);
    // debugging
    // return console.log(`Hash: ${hash}`);
    if (!this.isSHA256Hash(hash)) {
      return;
    }

    const newProofEndpoint = `${notaryBackendBase}/proofs/`;
    fetch(newProofEndpoint, {
      method: 'POST',
      body: hash,
    })
      .then(response => response.json())
      .then(
        // eslint-disable-next-line consistent-return
        response => {
          // console.log(response);
          if (response.status === 'ok') {
            // console.log(`Redirecting to /proof/?hash=${hash}`);
            return this.getProof(hash);
          }
          /* Sample successful response from notary.bitcoin.com
        {
          "status": "ok",
          "url": "/proof/ce4783b04b27b4d47cc06ca36a8c896fa783cf3326f8800b4250422a0107be3a"
        }

        Upgraded response:
        {
          "status": "ok",
          "url": "/proof/3f772cc2096a317100504d7958ab4c5467729f49205f7ca8bcac703807f71e27",
          "address": "127NN3Q4QHLqVMeTMeefCVVNdie3whcbpq",
          "price": 5000
        }
        */
          // parse the URL, don't got to a new URL but instead show a component to parse
          // get the QR code and payment info
          // you'll need to add a route handler to set state based on route later
        },
        err => {
          console.log(`Error in posting ${hash} to ${newProofEndpoint}:`);
          console.log(err);
        },
      );
  }

  // eslint-disable-next-line consistent-return
  rewatchProof(hash) {
    if (!this.isSHA256Hash(hash)) {
      return console.log(`Invalid hash provided to rewatchProof`);
    }
    console.log(`rewatchProof`);
    const refreshEndpoint = `${notaryBackendBase}/proofs/${hash}/refresh/`;
    // console.log(`refresh endpoint: ${refreshEndpoint}`);
    fetch(refreshEndpoint)
      .then(response => response.json())
      .then(
        response => {
          console.log(response);
          // Now watch for the anchor
        },
        err => {
          console.log(
            `Error in refreshing proof for ${hash} at ${notaryBackendBase}:`,
          );
          console.log(err);
        },
      );
  }

  checkSeenTx(hash) {
    // console.log(`checkSeenTx:`);
    this.setCheckAnchorInterval();

    // router.get("/proofs/:hash/refresh", routes.refreshProof);
    // also see what the balance left should be and update that
    /*
    const refreshEndpoint = `${notaryBackendBase}/proofs/${hash}/refresh/`;
    fetch(refreshEndpoint)
      .then(response => response.json())
      .then(
        response => {
          console.log(response);
          // Now watch for the anchor
        },
        err => {
          console.log(
            `Error in refreshing proof for ${hash} at ${notaryBackendBase}:`,
          );
          console.log(err);
        },
      );
      */
  }

  watchProofAddressForAnchor() {
    // console.log(`watchProofAddressForAnchor()`);
    const { address, price, inferredProofState } = this.state;
    const legacyAddress = bitbox.Address.toLegacyAddress(address);
    const insightApi = `https://explorer.api.bitcoin.com/bch/v1/txs/?address=${legacyAddress}`;
    fetch(insightApi)
      .then(response => response.json())
      .then(
        // eslint-disable-next-line consistent-return
        response => {
          // console.log(response);
          // console.log(`Transactions at address: ${response.txs.length}`);
          // if 1 tx and balance is more than price, it's funded
          // if 1 tx of more than price AND another tx, anchor is sent
          // could also parse anchor tx for the OP RETURN field to see if there's an anchor
          // Then see when it confirms
          // To simplify, you could just wait for all the tx at the address to have 1 conf
          // that would work for all cases except partial payment
          // so, only start watching once full payment is confirmed
          // Parse for price paid and anchor check
          let confirmed = true;
          let anchored = false;
          let anchorTxid = '';
          let anchorBroadcast = '';
          let paid = false;
          let pending = false;
          let balanceSats;

          let amtRecd = 0;

          const { txs } = response;
          for (let i = 0; i < txs.length; i += 1) {
            const tx = txs[i];
            // const { vin } = tx;
            const { vout } = tx;
            if (tx.confirmations === 0) {
              confirmed = false;
            }
            // console.log(`tx ${i + 1}: ${tx.txid}`);
            // console.log(`confirmations: ${tx.confirmations}`);
            /*
            for (let j = 0; j < vin.length; j += 1) {
              // console.log(`Parsing amtSent for tx ${i + 1}:`);
              // console.log(`${vin[j].addr}, ${address}`);
              // console.log(`${vin[j].valueSat}`);
              if (vin[j].addr !== address) {
                amtSent += 0;
              } else {
                amtSent += vin[j].valueSat;
              }
            }
            */
            for (let k = 0; k < vout.length; k += 1) {
              try {
                // console.log(`Parsing amount received for tx ${i + 1}`);
                // console.log(`${vout[k].scriptPubKey.addresses[0]}, ${address}`);
                // console.log(`${Math.round(parseFloat(vout[k].value) * 1e8)}`);
                if (typeof vout[k].scriptPubKey.addresses[0] !== 'undefined') {
                  if (vout[k].scriptPubKey.addresses[0] === legacyAddress) {
                    const value = Math.round(parseFloat(vout[k].value) * 1e8);
                    amtRecd += value;
                  } else {
                    amtRecd += 0;
                  }
                }
              } catch (err) {
                anchorTxid = tx.txid;
                anchorBroadcast = tx.blocktime;
                // console.log(`Found anchor tx: ${anchorTxid}`);
              }

              // parse for anchor and set anchored = true and get anchor tx txid
            }
          }
          if (amtRecd >= price) {
            paid = true;
          } else if (amtRecd > 0 && amtRecd < price) {
            pending = true;
            balanceSats = price - amtRecd;
          }

          // console.log(`price: ${price}`);
          // console.log(`amtRecd: ${amtRecd}`);
          // console.log(`amtSent: ${amtSent}`);

          if (confirmed && anchorTxid !== '') {
            anchored = true;
          }
          // possibilities
          // unpaid (paid = false)
          // paid no anchor (paid = true but anchorTxid === '')
          // paid and unconfirmed anchor (anchorTxid !== '')
          // anchored (anchored = true)

          // Prevent a proof regressing from anchoring
          if (inferredProofState !== 'anchoring') {
            if (!paid && !pending) {
              // console.log(`inferredProofState: unpaid`);
              return this.setState({ inferredProofState: 'unpaid' });
            }
            if (pending) {
              // console.log(`inferredProofState: pending`);
              return this.setState({
                inferredProofState: 'pending',
                inferredBalanceSats: balanceSats,
              });
            }
            if (paid && anchorTxid === '') {
              // console.log(`inferredProofState: paid`);
              return this.setState({ inferredProofState: 'paid' });
            }
            if (!anchored) {
              // console.log(`inferredProofState: anchoring`);
              return this.setState({
                inferredProofState: 'anchoring',
                inferredAnchorTxid: anchorTxid,
              });
            }
          }

          if (anchored) {
            // console.log(`inferredProofState: anchored`);
            return this.setState(
              {
                inferredProofState: 'confirmed',
                inferredAnchorTxid: anchorTxid,
                inferredAnchorBroadcast: anchorBroadcast,
              },
              this.clearCheckAnchorInterval(),
            );
          }

          /*
          {
          "pagesTotal": 1,
          "txs": [
            {
              "txid": "79df03bb837130bbcdde8260d15625a68eb7c803e650bb93e03b0524c2cff49c",
              "version": 2,
              "locktime": 0,
              "vin": [
                {
                  "txid": "cbe37d5628ed664d4ac1084b55b50a5ec90b0d6804b32f19fe1fd7f821f0a346",
                  "vout": 0,
                  "sequence": 4294967295,
                  "n": 0,
                  "scriptSig": {
                    "hex": "47304402207a90e1b9e909483a5a5cd98540b0212a8415b3acfbf31fb3b3640fb00d62c5b9022003057b3257eb23534ece6ffdd2f2cfc5dbf19ae8debdf047daee716b3a998ee24121034743cc898fab7c346348bbb048c4ae7cf2e133dca02c7a88bf65cedc8ada33e2",
                    "asm": "304402207a90e1b9e909483a5a5cd98540b0212a8415b3acfbf31fb3b3640fb00d62c5b9022003057b3257eb23534ece6ffdd2f2cfc5dbf19ae8debdf047daee716b3a998ee241 034743cc898fab7c346348bbb048c4ae7cf2e133dca02c7a88bf65cedc8ada33e2"
                  },
                  "addr": "186wocurARAyYTWn3c5u2NBUCQv39ayZw",
                  "valueSat": 5000,
                  "value": 0.00005,
                  "doubleSpentTxID": null
                }
              ],
              "vout": [
                {
                  "value": "0.00000000",
                  "n": 0,
                  "scriptPubKey": {
                    "hex": "6a264e6f74617279f292b90da0a07af292b1ea932dabddd41d3cefc05c986448822345fb6d1f4183",
                    "asm": "OP_RETURN 4e6f74617279f292b90da0a07af292b1ea932dabddd41d3cefc05c986448822345fb6d1f4183"
                  },
                  "spentTxId": null,
                  "spentIndex": null,
                  "spentHeight": null
                },
                {
                  "value": "0.00003800",
                  "n": 1,
                  "scriptPubKey": {
                    "hex": "76a91460454f0c63fb59e08788d4b05351eae9e9ad11aa88ac",
                    "asm": "OP_DUP OP_HASH160 60454f0c63fb59e08788d4b05351eae9e9ad11aa OP_EQUALVERIFY OP_CHECKSIG",
                    "addresses": [
                      "19n2u5KCLvJ2X2Mx9gs7HjHFY3RcRtC4fe"
                    ],
                    "type": "pubkeyhash"
                  },
                  "spentTxId": null,
                  "spentIndex": null,
                  "spentHeight": null
                }
              ],
              "blockheight": -1,
              "confirmations": 0,
              "time": 1585319601,
              "firstSeenTime": 1585319601,
              "valueOut": 0.000038,
              "size": 240,
              "valueIn": 0.00005,
              "fees": 0.000012
            },
            {
              "txid": "cbe37d5628ed664d4ac1084b55b50a5ec90b0d6804b32f19fe1fd7f821f0a346",
              "version": 2,
              "locktime": 0,
              "vin": [
                {
                  "txid": "58e484f58f7df3a0f257436ddd92b656feac740803b71a9f670763b39752d61d",
                  "vout": 9,
                  "sequence": 4294967295,
                  "n": 0,
                  "scriptSig": {
                    "hex": "483045022100dc7cb6b63b5a8fdac976223935e2ff808558419df8dd298a6eb58a527697e21902207ae2fc3f07c9d536336d2d7e9fc08239f4452cfbf1b277b33acbd3ada79e73ac412103a6e6185796661ffde1610cb11a2ccf3db5863f972607c63b1a51d33284119b67",
                    "asm": "3045022100dc7cb6b63b5a8fdac976223935e2ff808558419df8dd298a6eb58a527697e21902207ae2fc3f07c9d536336d2d7e9fc08239f4452cfbf1b277b33acbd3ada79e73ac41 03a6e6185796661ffde1610cb11a2ccf3db5863f972607c63b1a51d33284119b67"
                  },
                  "addr": "1KDhZxoDABVpXhoTdxtk2WEmf33nKamE7d",
                  "valueSat": 3573,
                  "value": 0.00003573,
                  "doubleSpentTxID": null
                },
                {
                  "txid": "1bbd9d29e757f8ecd20e96aa249b3fcdfba32f9782b9a57967d3d5a6bcd13d6f",
                  "vout": 0,
                  "sequence": 4294967295,
                  "n": 1,
                  "scriptSig": {
                    "hex": "4830450221009cf4bed4c8dc9ea8b880d9049f6a80b78e1a054a522bd1512d8e4e443a119ee0022003c40e9787b2c58d40bb21b48351f80a2104ef02c3f4f154cd486700b337d3c941210313f3602aa6c65b366c4aa7be93148a1746d6a1745c7634fd2de426d59dd49022",
                    "asm": "30450221009cf4bed4c8dc9ea8b880d9049f6a80b78e1a054a522bd1512d8e4e443a119ee0022003c40e9787b2c58d40bb21b48351f80a2104ef02c3f4f154cd486700b337d3c941 0313f3602aa6c65b366c4aa7be93148a1746d6a1745c7634fd2de426d59dd49022"
                  },
                  "addr": "16JmXPr5YQyuRwfTiPHAFKmah14sMowzYx",
                  "valueSat": 11543,
                  "value": 0.00011543,
                  "doubleSpentTxID": null
                }
              ],
              "vout": [
                {
                  "value": "0.00005000",
                  "n": 0,
                  "scriptPubKey": {
                    "hex": "76a9140157dfd0c0c1d9165f0b0827c951403fe76f144888ac",
                    "asm": "OP_DUP OP_HASH160 0157dfd0c0c1d9165f0b0827c951403fe76f1448 OP_EQUALVERIFY OP_CHECKSIG",
                    "addresses": [
                      "186wocurARAyYTWn3c5u2NBUCQv39ayZw"
                    ],
                    "type": "pubkeyhash"
                  },
                  "spentTxId": "79df03bb837130bbcdde8260d15625a68eb7c803e650bb93e03b0524c2cff49c",
                  "spentIndex": 0,
                  "spentHeight": -1
                },
                {
                  "value": "0.00009702",
                  "n": 1,
                  "scriptPubKey": {
                    "hex": "76a914c7d91ed08c92ef0c3757edd0971848db12a62bf388ac",
                    "asm": "OP_DUP OP_HASH160 c7d91ed08c92ef0c3757edd0971848db12a62bf3 OP_EQUALVERIFY OP_CHECKSIG",
                    "addresses": [
                      "1KDhZxoDABVpXhoTdxtk2WEmf33nKamE7d"
                    ],
                    "type": "pubkeyhash"
                  },
                  "spentTxId": null,
                  "spentIndex": null,
                  "spentHeight": null
                }
              ],
              "blockheight": -1,
              "confirmations": 0,
              "time": 1585319592,
              "firstSeenTime": 1585319592,
              "valueOut": 0.00014702,
              "size": 374,
              "valueIn": 0.00015116,
              "fees": 0.00000414
            }
          ]
        }
          */
        },
        err => {
          console.log(`Error in GET from ${insightApi}`);
          console.log(err);
        },
      );
  }

  parseWsTx(wsTx) {
    const { lastTxParsed } = this.state;
    const {
      intl: { formatMessage },
    } = this.props;
    if (wsTx.hash === lastTxParsed) {
      console.log(`App has already seen txid ${wsTx.hash}`);
      return;
    }
    const toastMsg = formatMessage({
      id: 'proof.toast.tx',
    });
    toast.notify(toastMsg, {
      position: 'bottom-right',
      duration: 3000,
    });
    const { address, balanceSats, inferredBalanceSats } = this.state;
    let properBalanceSats = balanceSats;
    if (inferredBalanceSats !== 0) {
      properBalanceSats = inferredBalanceSats;
    }
    const legacyAddress = bitbox.Address.toLegacyAddress(address);
    const txid = wsTx.hash;
    let amtRecd = 0;
    let anchorTxid = '';
    // Check if there is a BCH amount sent by proof address
    for (let i = 0; i < wsTx.inputs.length; i += 1) {
      if (wsTx.inputs[i].prev_out.addr === legacyAddress) {
        anchorTxid = txid;
      }
    }
    // Check amount received by proof address
    for (let i = 0; i < wsTx.out.length; i += 1) {
      if (wsTx.out[i].addr === legacyAddress) {
        amtRecd += wsTx.out[i].value;
      }
    }
    if (amtRecd < properBalanceSats && anchorTxid === '') {
      // return pending
      const nextInferredBalanceSats = properBalanceSats - amtRecd;
      // set balance -= amtRecd
      // eslint-disable-next-line consistent-return
      return this.setState({
        inferredProofState: 'pending',
        inferredBalanceSats: nextInferredBalanceSats,
        lastTxParsed: txid,
      });
    }
    if (amtRecd >= properBalanceSats && anchorTxid === '') {
      // return paid
      // eslint-disable-next-line consistent-return
      return this.setState({ inferredProofState: 'paid', lastTxParsed: txid });
    }

    if (anchorTxid !== '') {
      // return anchored and start interval
      // eslint-disable-next-line consistent-return
      return this.setState(
        {
          inferredProofState: 'anchoring',
          inferredAnchorTxid: anchorTxid,
          lastTxParsed: txid,
        },
        this.setCheckAnchorInterval(),
      );
    }
  }

  initializeWebsocket(address, hash) {
    // if it's not confirmed, watch the txs whether or not the websocket connects
    let legacyAddress = address;
    try {
      legacyAddress = bitbox.Address.toLegacyAddress(address);
    } catch (err) {
      console.log(`Error in bitbox.Address.toLegacyAddress(${address})`);
    }

    /*
    Implement watch tx regardless of websocket connection
    let { checkAnchorInterval } = this.state;
    if (checkAnchorInterval === null) {
      this.checkSeenTx(hash);
    }
    */

    let connectInterval;

    const ws = new WebSocket('wss://ws.blockchain.info/bch/inv');
    // test a failed ws connection
    // const ws = new WebSocket('wss://blockchain.com/notreal');
    const that = this; // cache the this
    ws.onmessage = event => {
      const wsTx = JSON.parse(event.data);
      console.log(`New tx:`);
      console.log(wsTx);
      this.parseWsTx(wsTx.x);

      /*
      Sample paying tx
      {
        "op": "utx",
        "x": {
          "lock_time": 628513,
          "ver": 1,
          "size": 219,
          "inputs": [
            {
              "sequence": 4294967294,
              "prev_out": {
                "spent": false,
                "tx_index": 0,
                "type": 0,
                "addr": "13VjXp7Mb4NYwaE6qe134eVMAnxFsURfCj",
                "value": 136400,
                "n": 3,
                "script": "76a9141b5f66dbee85a158f04e96cdb554a53881ac25bf88ac"
              },
              "script": "41173e5ba76f4957cc88b661544dff67383385e8e775ebcc1c6e6a18dafac28acf768c87a3cb9755454153985b0dfb8ca8a4aa92af8a5c6de690bb28cc4dce8fee41210354be91d80580696c438d4aced550e9b86b312a7b0b7ce3e52fecada0691aef1e"
            }
          ],
          "time": 1585457991,
          "tx_index": 0,
          "vin_sz": 1,
          "hash": "4233a2fb244c918241550773b888857ef249564d84670c08e20ff303e92d1ab1",
          "vout_sz": 2,
          "relayed_by": "",
          "out": [
            {
              "spent": false,
              "tx_index": 0,
              "type": 0,
              "addr": "1GKRebGLDUjaBzGDXuaf5hAG77DQ41UAgR",
              "value": 5000,
              "n": 0,
              "script": "76a914a805f3040b80f054ecbedb070322f56b201f809788ac"
            },
            {
              "spent": false,
              "tx_index": 0,
              "type": 0,
              "addr": "164sH3JYr2h5BNvgFBoFupvxTeqZrUn8Rm",
              "value": 131100,
              "n": 1,
              "script": "76a9143793d87614d3da5a884a02ca1eb064fcaf7e091a88ac"
            }
          ]
        }
      }

      Sample anchor tx

      {
  "op": "utx",
  "x": {
    "lock_time": 0,
    "ver": 2,
    "size": 240,
    "inputs": [
      {
        "sequence": 4294967295,
        "prev_out": {
          "spent": false,
          "tx_index": 0,
          "type": 0,
          "addr": "1GKRebGLDUjaBzGDXuaf5hAG77DQ41UAgR",
          "value": 5000,
          "n": 0,
          "script": "76a914a805f3040b80f054ecbedb070322f56b201f809788ac"
        },
        "script": "473044022063ab96350f194b482ee31ad885a46db766e5154c3ede81571559fd8966ea63df02205d632a124a9f87614eaa1491c90eef2e9053bf29e2f9207d4629cb094ca3a217412102a303c1c278af6ba020d50f143f5b0be72e96b8a6377df6b5862ac5828d6f92fe"
      }
    ],
    "time": 1585458054,
    "tx_index": 0,
    "vin_sz": 1,
    "hash": "77753dd6ddb65a3197ce1dbd97f88e29ccf495d9cf509ef23dddd7384df0624b",
    "vout_sz": 2,
    "relayed_by": "",
    "out": [
      {
        "spent": false,
        "tx_index": 0,
        "type": 0,
        "addr": "1NfokjgjVDNEuwGqU5T3oMaRXpxoRD3Gdj",
        "value": 4703,
        "n": 0,
        "script": "76a914edb179f147f8dc2eac98418a70a7556d13f72c4d88ac"
      },
      {
        "spent": false,
        "tx_index": 0,
        "type": 0,
        "addr": null,
        "value": 0,
        "n": 1,
        "script": "6a264e6f74617279d2af2e13cc452d93fc035b839dab389dc11e7b05711f9fb6021621172a6e2444"
      }
    ]
  }
}
      */
      // could check for balance remaining here or in checkSeenTx
      // if you get a tx, parse it the way you do in watchanchortx
      // check if the backend saw the payment, update status of the proof in client
    };
    ws.onopen = () => {
      console.log('Websocket connected');
      ws.send(JSON.stringify({ op: 'addr_sub', addr: legacyAddress }));
      console.log(`Subscribed to ${address} (aka ${legacyAddress} )`);
      this.setState({ ws });

      that.wsTimeout = 250; // reset timer to 250 on open of websocket connection
      clearTimeout(connectInterval); // clear Interval on on open of websocket connection
    };
    // websocket onclose event listener
    // eslint-disable-next-line consistent-return
    ws.onclose = e => {
      console.log(`Websocket closed.`);
      console.log(
        `Socket is closed. Reconnect will be attempted in ${Math.min(
          10000 / 1000,
          (that.wsTimeout + that.wsTimeout) / 1000,
        )} s`,
      );

      that.wsTimeout += that.wsTimeout; // increment retry interval
      console.log(that.wsTimeout);
      if (that.wsTimeout > 4000) {
        // stop trying to reconnect

        this.setCheckAnchorInterval();
        return console.log(`Websocket is unavailable.`);
      }

      connectInterval = setTimeout(
        this.reconnectWebsocket,
        Math.min(10000, that.wsTimeout),
      ); // call check function after timeout
    };
    ws.onerror = err => {
      console.error(
        'Webocket encountered error: ',
        err.message,
        'Closing websocket',
      );
      ws.close();
    };
  }

  reconnectWebsocket() {
    const { ws, address, hash } = this.state;
    if (!ws || ws.readyState === WebSocket.CLOSED) {
      this.initializeWebsocket(address, hash);
    }
  }

  parseHashUri() {
    const unparsed = window.location.search;
    const potentialHash = unparsed.substring(6, unparsed.length);
    // todo: validation goes here
    // console.log(potentialHash);

    this.setState({ hash: potentialHash }, this.getProof(potentialHash));
  }

  render() {
    const { locale } = this.props;
    const {
      hash,
      address,
      proofState,
      inferredProofState,
      balanceSats,
      inferredBalanceSats,
      serverStamp,
      anchorBroadcast,
      inferredAnchorBroadcast,
      anchorTx,
      inferredAnchorTxid,
      apiError,
      loading,
      hashError,
    } = this.state;
    // Calculate QR code string
    /*
    bitcoincash:1ADAMKpdpFSuduQcsfdNftRzXvuboB4YrV?amount=0.00005&label=Bitcoin%20Cash%20Notary&message=Proof%20of%20document%205370d8ce6ae85c8c104dcb684e348269f0414fa263b375ca524586ce24352076
    */

    let cashAddr = address;
    if (address !== '') {
      try {
        cashAddr = bitbox.Address.toCashAddress(address);
      } catch (err) {
        console.log(`Error in determining cashAddr:`);
        console.log(err);
      }
    }
    let renderedAnchorBroadcastStamp = anchorBroadcast;
    if (inferredAnchorBroadcast !== null) {
      renderedAnchorBroadcastStamp = inferredAnchorBroadcast;
    }
    const renderedAnchorDate = new Date(
      renderedAnchorBroadcastStamp * 1000,
    ).toLocaleString();

    let proofStateDependentComponent = <div />;
    let anchorTxid;
    // console.log(`anchorTx:`);
    // console.log(anchorTx);
    if (anchorTx !== null && anchorTx.length > 0) {
      try {
        anchorTxid = anchorTx[0].txid;
      } catch (err) {
        console.log(`Error determining anchorTxid`);
        console.log(err);
      }
    }

    if (inferredAnchorTxid !== null) {
      anchorTxid = inferredAnchorTxid;
    }
    let renderedBalanceSats = balanceSats;
    if (inferredBalanceSats !== 0) {
      renderedBalanceSats = inferredBalanceSats;
    }
    const balanceBch = bitbox.BitcoinCash.toBitcoinCash(renderedBalanceSats);

    const qrString = `${cashAddr}?amount=${balanceBch}&label=Bitcoin%20Cash%20Notary&message=Proof%20of%20document%20${hash}`;
    // if there is an inferred proof state available, it has priority
    let renderedProofState = proofState;
    if (inferredProofState !== null) {
      renderedProofState = inferredProofState;
    }
    switch (renderedProofState) {
      case 'unpaid':
        proofStateDependentComponent = (
          <QrContentBlock>
            <FileTitle>
              <FormattedMessage id="proof.invoice.title" />
            </FileTitle>
            <ConfirmedText>
              <FormattedMessage
                id="proof.invoice.subtitle"
                values={{ price: balanceBch, address: cashAddr }}
              />
            </ConfirmedText>
            <ConfirmedText>
              <FormattedMessage id="proof.invoice.desc" />
            </ConfirmedText>
            <QrWrapper padded centered>
              <QRCode
                value={qrString}
                size={400}
                renderAs="svg"
                includeMargin
                imageSettings={{
                  src: bchLogo,
                  x: null,
                  y: null,
                  height: 67,
                  width: 67,
                  excavate: false,
                }}
              />
              <CopyToClipboard
                text={qrString}
                onCopy={() => this.handleUriCopied()}
              >
                <ProofButton style={{ marginTop: '24px' }}>
                  <FormattedMessage id="proof.buttons.copy" />
                </ProofButton>
              </CopyToClipboard>
            </QrWrapper>
          </QrContentBlock>
        );
        break;
      case null:
        proofStateDependentComponent = <></>;
        break;
      case 'pending':
        proofStateDependentComponent = (
          <QrContentBlock>
            <FileTitle>
              <FormattedMessage id="proof.invoice.partial" />
            </FileTitle>
            <ConfirmedText>
              <FormattedMessage
                id="proof.invoice.subtitle"
                values={{ price: balanceBch, address: cashAddr }}
              />
            </ConfirmedText>
            <ConfirmedText>
              <FormattedMessage id="proof.invoice.desc" />
            </ConfirmedText>
            <QrWrapper padded centered>
              <QRCode
                value={qrString}
                size={400}
                renderAs="svg"
                includeMargin
                imageSettings={{
                  src: bchLogo,
                  x: null,
                  y: null,
                  height: 67,
                  width: 67,
                  excavate: false,
                }}
              />
              <CopyToClipboard
                text={qrString}
                onCopy={() => this.handleUriCopied()}
              >
                <ProofButton style={{ marginTop: '24px' }}>
                  <FormattedMessage id="proof.buttons.copy" />
                </ProofButton>
              </CopyToClipboard>
            </QrWrapper>
          </QrContentBlock>
        );
        break;
      case 'paid':
        proofStateDependentComponent = (
          <QrContentBlock>
            <FileTitle>
              <FormattedMessage id="proof.file.paidTitle" />
            </FileTitle>
            <ConfirmedText>
              <FormattedMessage id="proof.file.paidDesc" />
            </ConfirmedText>
            <QrWrapper padded centered>
              <Lottie
                options={{
                  animationData: InvoicePaid,
                  loop: false,
                }}
              />
            </QrWrapper>
          </QrContentBlock>
        );
        break;
      case 'anchoring':
        proofStateDependentComponent = (
          <QrContentBlock>
            <FileTitle>
              <FormattedMessage id="proof.file.anchoringTitle" />
            </FileTitle>
            <ConfirmedText>
              <FormattedMessage id="proof.file.anchoringDesc" />
              <br />
              {typeof anchorTxid !== 'undefined' && (
                <AnchorLink
                  href={`https://explorer.bitcoin.com/bch/tx/${anchorTxid}`}
                  rel="nofollow"
                  target="_blank"
                >
                  <FormattedMessage id="proof.file.anchoringLink" />
                </AnchorLink>
              )}
            </ConfirmedText>
            <QrWrapper padded centered>
              <Lottie
                options={{
                  animationData: InvoicePaid,
                  loop: false,
                }}
              />
            </QrWrapper>
          </QrContentBlock>
        );
        break;
      case 'confirmed':
        proofStateDependentComponent = (
          <QrContentBlock>
            <FileTitle>
              <FormattedMessage id="proof.file.confirmedTitle" />
              <ConfirmedIcon size={32} style={{ paddingLeft: '24px' }} />
            </FileTitle>
            <ConfirmedText>
              {typeof anchorTxid !== 'undefined' && (
                <AnchorLink
                  href={`https://explorer.bitcoin.com/bch/tx/${anchorTxid}`}
                  rel="nofollow"
                  target="_blank"
                >
                  <FormattedMessage id="proof.file.confirmedDesc" />
                </AnchorLink>
              )}
            </ConfirmedText>
            <QrWrapper padded centered>
              <Lottie
                options={{
                  animationData: InvoicePaid,
                  loop: false,
                }}
              />
            </QrWrapper>
          </QrContentBlock>
        );
        break;
      default:
        proofStateDependentComponent = (
          <QrContentBlock>
            <FileTitle>
              <FormattedMessage id="proof.invoice.title" />
            </FileTitle>
            <ConfirmedText>
              <FormattedMessage
                id="proof.invoice.subtitle"
                values={{ price: balanceBch, address: cashAddr }}
              />
            </ConfirmedText>
            <ConfirmedText>
              <FormattedMessage id="proof.invoice.desc" />
            </ConfirmedText>
            <QrWrapper padded centered>
              <QRCode
                value={qrString}
                size={400}
                renderAs="svg"
                includeMargin
                imageSettings={{
                  src: bchLogo,
                  x: null,
                  y: null,
                  height: 67,
                  width: 67,
                  excavate: false,
                }}
              />
              <CopyToClipboard
                text={qrString}
                onCopy={() => this.handleUriCopied()}
              >
                <ProofButton style={{ marginTop: '24px' }}>
                  <FormattedMessage id="proof.buttons.copy" />
                </ProofButton>
              </CopyToClipboard>
            </QrWrapper>
          </QrContentBlock>
        );
    }

    return (
      <>
        <SEO proofState={renderedProofState} />
        <Wrapper>
          <HeadSection>
            <Nav locale={locale} contrast languages={['en', 'zh']} />
          </HeadSection>
          {apiError && (
            <FileSection>
              <FileContentBlock>
                <HeaderLinkText>
                  <HeaderLink href="/">
                    <FormattedMessage id="proof.headerlink.home" />
                  </HeaderLink>{' '}
                  / <FormattedMessage id="proof.headerlink.proof" />
                </HeaderLinkText>

                <ApiErrorCard centered padded direction="column">
                  <FileTitle>
                    <FormattedMessage id="proof.apiError.title" />
                  </FileTitle>
                  <Alert>
                    <FormattedMessage id="proof.apiError.msg" />
                  </Alert>
                  <ProofButton onClick={this.parseHashUri}>
                    <FormattedMessage id="proof.buttons.retry" />
                  </ProofButton>
                </ApiErrorCard>
              </FileContentBlock>
            </FileSection>
          )}
          {loading && (
            <FileSection>
              <FileContentBlock>
                <HeaderLinkText>
                  <HeaderLink href="/">Home</HeaderLink> / Document Proof
                </HeaderLinkText>
                <FileTitle>
                  <FormattedMessage id="proof.file.loadingTitle" />
                </FileTitle>
                <FileLoader />
              </FileContentBlock>
            </FileSection>
          )}
          {!loading && !apiError && (
            <FileSection>
              <FileContentBlock>
                <HeaderLinkText>
                  <HeaderLink href="/">Home</HeaderLink> / Document Proof
                </HeaderLinkText>
                <FileTitle>
                  {renderedProofState === 'confirmed' && (
                    <ConfirmedIcon size={16} style={{ paddingRight: '12px' }} />
                  )}
                  <FormattedMessage id="proof.file.title" />
                </FileTitle>

                <FileTable columns={2}>
                  <FileTr>
                    <FileTd>
                      <FormattedMessage id="proof.file.table.docHash" />
                    </FileTd>
                    <HashTd alert={hashError}>{hash}</HashTd>
                  </FileTr>
                  {!hashError && (
                    <FileTr>
                      <FileTd>
                        <FormattedMessage id="proof.file.table.timestamp" />
                      </FileTd>
                      <ValueTd>
                        {new Date(serverStamp * 1000).toLocaleString()}
                      </ValueTd>
                    </FileTr>
                  )}
                  <FileTr>
                    <FileTd>
                      {renderedProofState === 'confirmed' && (
                        <ConfirmedIcon
                          size={16}
                          style={{ paddingRight: '12px' }}
                        />
                      )}
                      <FormattedMessage id="proof.file.table.broadcast" />
                    </FileTd>

                    {renderedProofState === 'confirmed' && (
                      <ValueTd>{renderedAnchorDate}</ValueTd>
                    )}
                    {renderedProofState === 'anchoring' && (
                      <ValueTd
                        style={{ textAlign: 'left', fontStyle: 'italic' }}
                      >
                        <FormattedMessage id="proof.file.table.pendingConf" />
                      </ValueTd>
                    )}
                    {renderedProofState !== 'confirmed' &&
                      renderedProofState !== 'anchoring' &&
                      !hashError && (
                        <ValueTd
                          style={{ textAlign: 'left', fontStyle: 'italic' }}
                        >
                          <FormattedMessage id="proof.file.table.pending" />
                        </ValueTd>
                      )}
                    {hashError && (
                      <ValueTd
                        style={{ textAlign: 'left', fontStyle: 'italic' }}
                      >
                        <FormattedMessage id="proof.file.table.hashError" />
                      </ValueTd>
                    )}
                  </FileTr>
                </FileTable>
                {hashError && (
                  <Alert style={{ paddingTop: '24px' }}>
                    <FormattedMessage id="proof.hashError.msg" />
                  </Alert>
                )}
              </FileContentBlock>
              {proofStateDependentComponent}
            </FileSection>
          )}

          <Footer locale={locale} />
        </Wrapper>
      </>
    );
  }
}

Proof.propTypes = {
  locale: PropTypes.string.isRequired,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }).isRequired,
};

export default injectIntl(Proof);
