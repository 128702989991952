import styled from 'styled-components';
import {
  Section,
  ContentBlock,
  H3,
  H5,
  Table,
  Card,
  CheckSolid,
  Paragraph,
  Button,
  Link,
  Loader,
} from '@bitcoin-portal/bitcoincom-pkg-components';
import { colors, media } from '@bitcoin-portal/bitcoincom-pkg-theme';

export const ProofButton = styled(Button)``;

export const FileLoader = styled(Loader)``;

export const HeaderLink = styled(Link)``;
export const AnchorLink = styled(Link)``;

export const FileTitle = styled(H3)``;
export const FileSubtitle = styled(H5)`
  @media screen and (min-width: 850px) {
    display: none;
  }
  word-break: break-all;
`;
export const QrWrapper = styled(Card)`
  max-width: 450px;
  padding: 32px;
  margin: auto;
`;
export const ApiErrorCard = styled(Card)``;
export const Alert = styled(Paragraph)`
  color: ${colors.solid.cinnabar};
`;
export const FileTable = styled(Table)`
  max-width: 900px;
  margin: auto;
`;
export const ConfirmedText = styled(Paragraph)``;
export const HeaderLinkText = styled(Paragraph)`
  text-align: left;
`;
export const FileThead = styled.div``;
export const FileTr = styled.div``;
export const FileTd = styled.div`
  text-align: right;
  width: 134px;
`;
export const ValueTd = styled.div`
  text-align: left;
  padding-left: 12px;
`;
export const HashTd = styled.div`
  padding-left: 12px;
  max-width: 750px;
  word-break: break-all;
  color: ${({ alert = false }) =>
    alert === true ? colors.solid.cinnabar : colors.solid.vulcan};
`;

export const ConfirmedIcon = styled(CheckSolid)`
  color: ${colors.solid.caribbeanGreen};
`;

export const Wrapper = styled.div`
  width: 100%;
  position: relative;
  box-sizing: border-box;
  background: ${({ theme }) => theme.palette.background.contrast};
`;

export const HeadSection = styled.div`
  background-color: ${({ theme }) => theme.palette.background.dark};
`;

export const CustomContentBlock = styled(ContentBlock)`
  background: ${({ theme }) => theme.palette.background.contrast};
  & > div > div:first-child > img {
    max-height: 300px;
  }

  & h1 {
    ${({ theme }) => theme.palette.primary.text};
  }

  ${media.md`
    & > div {
      justify-content: space-between;
    }

    & > div > div {
      max-width: 46%;
    }

    & > div > div:first-child > img {
      max-height: 400px;
    }
  `};
`;

export const Logo = styled.img`
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spacing.unit * 2}px;
`;

export const FileSection = styled(Section)`
  background-color: ${colors.solid.zircon};
`;
export const FileContentBlock = styled(ContentBlock)`
  text-align: center;
  padding: 32px 0;
`;
export const QrContentBlock = styled(ContentBlock)`
  text-align: center;
`;
