import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { Helmet } from 'react-helmet';

const SEO = ({ intl, proofState }) => (
  <Helmet
    title={
      proofState === 'unpaid'
        ? intl.formatMessage({ id: 'proof.meta.titleUnpaid' })
        : intl.formatMessage({ id: 'proof.meta.title' })
    }
  >
    <meta
      name="description"
      content={intl.formatMessage({
        id: 'proof.meta.description',
      })}
    />

    <meta
      property="og:title"
      content={intl.formatMessage({ id: 'proof.meta.title' })}
    />

    <meta
      property="og:title"
      content={intl.formatMessage({ id: 'proof.meta.title' })}
    />
    <meta
      property="og:description"
      content={intl.formatMessage({
        id: 'proof.meta.description',
      })}
    />
    <meta
      property="og:image"
      content={`https://www.bitcoin.com${intl.messages['proof.meta.image']}`}
    />
    <meta name="twitter:card" content="summary_large_image" />
    <meta
      name="twitter:site"
      content={`@${intl.formatMessage({ id: 'proof.meta.twitter' })}`}
    />
    <meta
      name="twitter:title"
      content={intl.formatMessage({ id: 'proof.meta.title' })}
    />
    <meta
      name="twitter:description"
      content={intl.formatMessage({
        id: 'proof.meta.description',
      })}
    />
    <meta
      name="twitter:image"
      content={`https://www.bitcoin.com${intl.messages['proof.meta.image']}`}
    />
    <script type="application/ld+json">
      {JSON.stringify(intl.messages['proof.meta.schema'])}
    </script>

    <link rel="canonical" href="https://www.bitcoin.com/" />
  </Helmet>
);

SEO.propTypes = {
  proofState: PropTypes.string,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
    messages: PropTypes.object,
  }).isRequired,
};
SEO.defaultProps = {
  proofState: 'unpaid',
};

export default injectIntl(SEO);
